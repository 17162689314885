import Work1 from '../../assets/fiventhrift.jpg';
import Work2 from '../../assets/cms-fivenstore.jpg';
import Work3 from '../../assets/work3.jpg';
import Work4 from '../../assets/work4.jpg';
import WiseWallet from '../../assets/WiseWallet.png';
import Breatheee from '../../assets/Breatheee.png';
import Pawse from '../../assets/Pawse.png';

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: 'Fiventhrift E-Commerce',
    category: 'Web',
    link: 'https://www.fiventhrift.shop/',
  },
  {
    id: 2,
    image: Work2,
    title: 'CMS for Fiventhrift',
    category: 'Web',
    link: 'https://calo-hacker-adm.vercel.app/',
  },
  {
    id: 3,
    image: Work3,
    title: 'Online Food',
    category: 'Design',
    link: 'https://www.figma.com/file/XIAoQV7crOjT431NNgp7yD/MuhamadKevinFaruz_PABCL_UI%2FUX_2022?type=design&node-id=0%3A1&mode=design&t=9WqigM0HlD1Qd94w-1',
  },
  {
    id: 4,
    image: WiseWallet,
    title: 'WiseWallet',
    category: 'app',
    link: 'https://github.com/embunazzahra/Budgeting-Calculator-for-Ederly.git',
  },
  {
    id: 5,
    image: Breatheee,
    title: 'Breatheee',
    category: 'app',
    link: 'https://github.com/JonathanAaron3005/Mini3_AsthmaApp',
  },
  {
    id: 6,
    image: Pawse,
    title: 'Pawse',
    category: 'app',
    link: 'https://github.com/PAWse-AppleDeveloper/Pawse.git',
  },
  // {
  //   id: 5,
  //   image: Work5,
  //   title: 'Web design',
  //   category: 'Web',
  //   link: 'https://www.fiventhrift.shop/',
  // },
];

export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'Web',
  },
  {
    name: 'app',
  },
  {
    name: 'design',
  },
];
